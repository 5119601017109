<template>
  <div class="guestContainer">
    <el-table :data="tableData">
      <el-table-column type="index" prop="index" label="序号"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="" label="日期">
        <template slot-scope="scope">
          {{ formatShowDate(scope.row.date) }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="开始时间">
        <template slot-scope="scope">
          {{ scope.row.start_time }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="总时长">
        <template slot-scope="scope"> {{ scope.row.duration }}分钟 </template>
      </el-table-column>
      <el-table-column prop="show_end_time" label="结束时间"> </el-table-column>
      <el-table-column prop="" label="赞助商">
        <template slot-scope="scope">
          {{ scope.row.partner ? scope.row.partner.name : "" }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="会议房间">
        <template slot-scope="scope">
          {{ scope.row.meetingRoom ? scope.row.meetingRoom.name : "" }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="360px">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="toPPT(scope.row)"
            >文件列表</el-button
          >

          <el-button type="primary" size="small" @click="toPPT(scope.row)"
            >咨询消息</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getGuestSessions } from "../../api/api";
import { formatDate } from "../../utils/date";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getGuestSessions({
        guest_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    formatShowDate(time) {
      return formatDate(new Date(time * 1000), "yyyy-MM-dd");
    },
    toPPT(data) {
      this.$router.push({
        path: "/session/pptList",
        query: {
          session_id: data._id.$id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.guestContainer {
  padding: 20px;
}
</style>